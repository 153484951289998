<template>
    <div class="container">
        <!-- <banner></banner> -->
        <div style="padding-top: 10px">
            <bread-page>
                <menu-page>
                    <div class="menu-content" slot="menuList">
                        <div class="menu-left-con">
                            <img src="../../assets/gongxu_icon_xuqiuxinxi@3x.png" alt="" />
                            <div class="menu-left-con_title">IP转化数据</div>
                        </div>
                        <el-menu :default-active="active" class="menu-content menu-center" unique-opened router>
                            <sys-menu v-for="item in menus" :menu="item" :key="item.id"></sys-menu>
                        </el-menu>
                    </div>

                    <div class="main">
                        <router-view></router-view>
                    </div>
                </menu-page>
            </bread-page>
        </div>
    </div>
</template>

<script>
import Banner from '../../components/Banner.vue';
import MenuPage from '../../components/page/MenuPage.vue';
import BreadPage from '../../components/page/BreadPage.vue';
import SysMenu from '../../components/SysMenu.vue';
import menu from '../../mixins/menu';
export default {
    components: { MenuPage, Banner, SysMenu, BreadPage },
    mixins: [menu],
    data() {
        return {};
    },
    watch: {
        $route() {
            // this.scrollTo();
        }
    },
    mounted() {
        this.$http
            .post('navigation/backAll', {
                type: 'POLICY'
            })
            .then(res => {
                this.menus = res;
                this.findActive();
                // this.scrollTo(true);
            });
    }
};
</script>

<style lang="less" scoped>
.container {
    background-color: @bg;
}

.main {
    min-height: 1080px;
    flex-grow: 1;
    .flex-col();
}
.menu-left-con {
    height: 60px;
    background: #01a041;
    display: flex;
    padding-left: 34px;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    .menu-left-con_title{
        font-size: 16px;
    }
    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}
.menu-content {
    min-height: 800px;
    background-color: #fff;
}

.menu-center {
    &.el-menu {
        /deep/ .el-menu-item {
            padding-left: 68px !important;
        }
    }
}
/deep/ .el-menu > .el-menu-item {
    &.is-active {
        background: linear-gradient(90deg, rgba(1, 160, 65, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
        color: #01a041;
    }
}
&.is-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 1px;
    background-color: #01a041;
}
</style>
